<template>
  <div class="wide-highlight-container">
    <WideHighlight
      v-for="(highlight, index) in highlights"
      :key="index"
      :background-image="highlight.backgroundImage"
      :weak-text="highlight.weakText"
      :project-url="highlight.projectUrl"
      :class="{'wide-highlight': true, 'no-border': index === highlights.length - 1}"
    />
  </div>
</template>

<script>
import WideHighlight from './WideHighlight.vue';
import highlightedProducts from '@/assets/highlighted_products.json';

export default {
  name: 'WideHighlightContainer',
  components: {
    WideHighlight,
  },
  data() {
    return {
      highlights: highlightedProducts.products.map(product => ({
        weakText: product.description,
        backgroundImage: product.image_url,
        projectUrl: product.project_url
      }))
    };
  },
};
</script>

<style scoped>
.wide-highlight-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wide-highlight {
  border-bottom: 1px solid #ccc;
}

.no-border {
  border-bottom: none;
}
</style>
